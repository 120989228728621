import {
    AddBox,
    Check,
    DeleteOutline,
    Edit,
    SaveAlt,
    FilterList,
    FirstPage,
    LastPage,
    ChevronRight,
    ChevronLeft,
    Clear,
    Search,
    ArrowDownward,
    Remove,
    ViewColumn,
} from "@material-ui/icons";
import { forwardRef } from "react";
import { getYearDDL } from "../Redux/APIs/api_Common";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import TableChartIcon from "@material-ui/icons/TableChart";
import StorageIcon from "@material-ui/icons/Storage";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import { Button } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
const Buffer = require('buffer').Buffer;
export const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} style={{ color: "var(--red)" }} />
    )),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => (
        <Search {...props} ref={ref} style={{ fontSize: "2.5rem" }} />
    )),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const tableIconsInputScreen = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => (
        <Check {...props} style={{ fontSize: "large", padding: 0 }} ref={ref} />
    )),
    Clear: forwardRef((props, ref) => (
        <Clear {...props} style={{ fontSize: "large", padding: 0 }} ref={ref} />
    )),
    Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} style={{ color: "var(--red)" }} />
    )),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => (
        <Edit {...props} style={{ fontSize: "large", padding: 0 }} ref={ref} />
    )),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => (
        <Search {...props} ref={ref} style={{ fontSize: "2.5rem" }} />
    )),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
export let customStylesDefault = {
    control: (provided, state) => ({
        ...provided,
        border: "none",
        borderBottom: `1px solid #00B3B3`,
        borderRadius: 0,
        cursor: "text",
        boxShadow: "0 0 0 0 transparent !important",
        minHeight: 0,
        ":hover": {
            border: "none",
            borderBottom: `1px solid #00B3B3`,
            borderRadius: 0,
            boxShadow: "0 0 0 0 transparent !important",
        },
    }),
    singleValue: (provided, state) => ({
        ...provided,
        textAlign: "left",
    }),
    input: (provided, state) => ({
        ...provided,
        textAlign: "left",
        padding: 0,
    }),
    menu: (provided, state) => ({
        ...provided,
        textAlign: "left",
        backgroundColor: `var(--select-active-bg-color)`,
        color: `var(--select-active-text-color)`,
    }),
    menuList: (provided, state) => ({
        ...provided,
        color: `var(--select-active-text-color)`,
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        padding: "1px !important",
        cursor: "pointer",
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: "1px !important",
        cursor: "pointer",
    }),
};
export let customStylesYear = {
    control: (provided, state) => ({
        ...provided,
        cursor: "text",
        boxShadow: "0 0 0 0 transparent !important",
        minHeight: 0,
        ":hover": {
            boxShadow: "0 0 0 0 transparent !important",
        },
    }),
    singleValue: (provided, state) => ({
        ...provided,
        textAlign: "left",
    }),
    input: (provided, state) => ({
        ...provided,
        textAlign: "left",
        padding: 0,
    }),
    menu: (provided, state) => ({
        ...provided,
        textAlign: "left",
        backgroundColor: `var(--select-active-bg-color)`,
        color: `var(--select-active-text-color)`,
    }),
    menuList: (provided, state) => ({
        ...provided,
        color: `var(--select-active-text-color)`,
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        padding: "1px !important",
        cursor: "pointer",
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: "1px !important",
        cursor: "pointer",
    }),
};
export const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: `var(--side-drawer-bg-color)`,
        primary25: `var(--app-permission-list-item-color)`,
        primary50: `var(--app-permission-list-item-color)`,
    },
});
export const homeScreenIconStyle = {
    padding: "5px",
    margin: "5px -20px 0px 10px",
};
export const LandingAdmin = [
    {
        roleId: 1,
        role: "Admin",
        menuLink: "/orderList",
        menuName: "Sales Planning List",
        icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
    },
    {
        roleId: 1,
        role: "Admin",
        menuLink: "/uploads",
        menuName: "Uploads",
        icon: <UploadIcon style={homeScreenIconStyle} />,
    },
    {
        roleId: 1,
        role: "Admin",
        menuLink: "/masterData",
        menuName: "Master Data",
        icon: <StorageIcon style={homeScreenIconStyle} />,
    },
    {
        roleId: 1,
        role: "Admin",
        menuLink: "/userManagement",
        menuName: "User Management",
        icon: <GroupAddIcon style={homeScreenIconStyle} />,
    },
    {
        roleId: 1,
        role: "Admin",
        menuLink: "/dumpReports",
        menuName: "Reports",
        icon: <TableChartIcon style={homeScreenIconStyle} />,
    },
    {
        roleId: 1,
        role: "Admin",
        menuLink: "/delegate",
        menuName: "Delegate",
        icon: <TableChartIcon style={homeScreenIconStyle} />,
    },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/ackorder",
    //   menuName: "Acknowledge Production Orders",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/dispatchdetails",
    //   menuName: "Production Order Dispatch Details",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/grn",
    //   menuName: "GRN Production Orders",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/storeGRN",
    //   menuName: "Store GRN",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/reprintqr",
    //   menuName: "Re-print QR Code",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/admin",
    //   menuName: "Administrator",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/bufferlocation1",
    //   menuName: "Buffer Location1",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/testing",
    //   menuName: "Testing",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/bufferlocation2",
    //   menuName: "Buffer Location2",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/posttesting",
    //   menuName: "Post Testing",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/printing",
    //   menuName: "Printing",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/packing",
    //   menuName: "Packing",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/visual",
    //   menuName: "Visual",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/stagePD",
    //   menuName: "PD",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/storePD",
    //   menuName: "Store PD",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/stageBDC",
    //   menuName: "BDC",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/storeBDC",
    //   menuName: "Store BDC",
    // },
];
export const LandingGLSGL = [
    {
        roleId: 5,
        role: "GL/SGL",
        menuLink: "/orderList",
        menuName: "Sales Planning List",
        icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
    },
    {
        roleId: 5,
        role: "GL/SGL",
        menuLink: "/dumpReports",
        menuName: "Reports",
        icon: <TableChartIcon style={homeScreenIconStyle} />,
    },
];
export const LandingSE = [
    {
        roleId: 6,
        role: "SE",
        menuLink: "/orderList",
        menuName: "Sales Planning List",
        icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
    },
    {
        roleId: 6,
        role: "SE",
        menuLink: "/dumpReports",
        menuName: "Reports",
        icon: <TableChartIcon style={homeScreenIconStyle} />,
    },
];
export const LandingBM = [
    {
        roleId: 10,
        role: "Admin",
        menuLink: "/orderList",
        menuName: "Sales Planning List",
        icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
    },
    {
        roleId: 10,
        role: "Admin",
        menuLink: "/uploads",
        menuName: "Uploads",
        icon: <UploadIcon style={homeScreenIconStyle} />,
    },
    {
        roleId: 10,
        role: "Admin",
        menuLink: "/masterData",
        menuName: "Master Data",
        icon: <StorageIcon style={homeScreenIconStyle} />,
    },
    {
        roleId: 10,
        role: "Admin",
        menuLink: "/userManagement",
        menuName: "User Management",
        icon: <GroupAddIcon style={homeScreenIconStyle} />,
    },
    {
        roleId: 10,
        role: "Admin",
        menuLink: "/dumpReports",
        menuName: "Reports",
        icon: <TableChartIcon style={homeScreenIconStyle} />,
    },
    {
        roleId: 10,
        role: "Admin",
        menuLink: "/delegate",
        menuName: "Delegate",
        icon: <TableChartIcon style={homeScreenIconStyle} />,
    },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/ackorder",
    //   menuName: "Acknowledge Production Orders",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/dispatchdetails",
    //   menuName: "Production Order Dispatch Details",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/grn",
    //   menuName: "GRN Production Orders",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/storeGRN",
    //   menuName: "Store GRN",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/reprintqr",
    //   menuName: "Re-print QR Code",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/admin",
    //   menuName: "Administrator",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/bufferlocation1",
    //   menuName: "Buffer Location1",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/testing",
    //   menuName: "Testing",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/bufferlocation2",
    //   menuName: "Buffer Location2",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/posttesting",
    //   menuName: "Post Testing",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/printing",
    //   menuName: "Printing",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/packing",
    //   menuName: "Packing",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/visual",
    //   menuName: "Visual",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/stagePD",
    //   menuName: "PD",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/storePD",
    //   menuName: "Store PD",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/stageBDC",
    //   menuName: "BDC",
    // },
    // {
    //   roleId: 1,
    //   role: "Administrator",
    //   menuLink: "/storeBDC",
    //   menuName: "Store BDC",
    // },
];
export const MasterRouteLanding = [
    ...LandingAdmin,
    ...LandingSE,
    ...LandingGLSGL,
    ...LandingBM
    // ...LandingStoreGRN,
    // ...LandingOutSourcing,
    // ...LandingProduction,
    // ...LandingQA,
    // ...LandingBDC,
    // ...LandingStageGRN,
    // ...LandingStageBL1,
    // ...LandingStageTesting,
    // ...LandingStageBL2,
    // ...LandingStagePostTesting,
    // ...LandingStagePrinting,
    // ...LandingStageVisual,
    // ...LandingStagePacking,
    // ...LandingStagePD,
    // ...LandingStageBDC,
];

export const baseApiURL = "https://salesplanningtooluat.di-in.siemens.cloud/api";
//export const baseApiURL = "https://salesplanningtool.di-in.siemens.cloud/api";
//export const baseApiURL = "https://localhost:44338/api";

//   export function logoutUser() {
//     var newurl =
//       window.location.protocol +
//       "//" +
//       window.location.host +
//       window.location.pathname;
//     window.history.pushState({ path: newurl }, "", `/`);
//     localStorage.clear();
//     sessionStorage.clear();
//     caches.keys().then((names) => {
//       names.forEach((name) => {
//         caches.delete(name);
//       });
//     });
//     window.location.href = "/";
//   }
export const tableCellPreviousFY = {
    background: `var(--light-red)`,
};
export const tableCellCurrentFY = {
    background: `var(--green-type-1)`,
};
export const tdInputPreviousFY = {
    background: `var(--light-sand--type-1)`,
};
export const tdInputCurrentFY = {
    background: `var(--light-sand)`,
};
export const thInputPreviousFY = {
    background: `var(--light-sand--type-1)`,
    lineHeight: `0.5rem`,
    fontWeight: `bold`,
};
export const thInputCurrentFY = {
    background: `var(--light-sand)`,
    lineHeight: `0.65rem`,
    fontWeight: `bold`,
};
export const tdTotalInPut = {
    background: `var(--gold-type-1)`,
    // border:'1px solid',
};
export const getCurrentFiscalYear = () => {
    //get current date
    var today = new Date();
    //get current month
    var curMonth = today.getMonth();

    var fiscalYr = "";
    if (curMonth > 8) {
        //
        var nextYr1 = (today.getFullYear() + 1).toString();
        fiscalYr =
            today.getFullYear().toString() +
            "-" +
            nextYr1.charAt(2) +
            nextYr1.charAt(3);
    } else {
        var nextYr2 = today.getFullYear().toString();
        fiscalYr =
            (today.getFullYear() - 1).toString() +
            "-" +
            nextYr2.charAt(2) +
            nextYr2.charAt(3);
    }
    return fiscalYr;
    //document.write(fiscalYr);
};
export const getCurrentMonth = () => {
    // var today = new Date();
    // //get current month
    // var curMonth = today.getMonth();
    // return curMonth;
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const d = new Date();
    return monthNames[d.getMonth()]
}
export const getRoundUptoXDecimals = (value, decimal) => {
    let factor = Math.pow(10, decimal);
    return Math.round(value * factor + Number.EPSILON) / factor;
};

export const getURLParameter = (sParam) => {
    var sPageURL = Buffer.from(
        window.location.search.substring(1),
        "base64"
    ).toString();
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split("=");
        var value = "";
        for (var j = 0; j < sParameterName.length; j++) {
            if (sParameterName[j] == sParam) {
                for (var k = j + 1; k < sParameterName.length; k++) {
                    value = value + sParameterName[k];
                }
                break;
            }
            //return sParameterName[1];
        }
        if (!value) {
            continue;
        } else {
            break;
        }
    }
    return decodeURI(value);
};
export const renderValueWithThousandSeparator = (value) => {
    return !value
        ? value
        : value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};
export const spocEmail = "mayukh.mukherjee.ext@siemens.com";
export const myITPortal = "https://siemens.service-now.com/myitportal";

export const GetAddButton = () => {
    return <Button className="pr-button--primary ">Add</Button>;
};
export const GetEditButton = () => {
    return <Button className="pr-button--primary">Edit</Button>;
};
export const GetExportButton = () => {
    return <Button className="pr-button--primary">Export</Button>;
};

export const sideDrawerItemsGeneric = [
    {
        icon: <HomeIcon />,
        title: "Home",
        link: "/",
        roleId: [1, 2, 3, 4, 5, 6, 7, 8,10],
    },
    {
        icon: <ListAltOutlinedIcon />,
        title: "Sales Planning List",
        link: "orderList",
        roleId: [1, 2, 3, 4, 5, 6, 7, 8,10],
    },
    {
        icon: <UploadIcon />,
        title: "Uploads",
        link: "uploads",
        roleId: [1,10],
    },
    {
        icon: <StorageIcon />,
        title: "Master Data",
        link: "masterData",
        roleId: [1,10],
    },
    {
        icon: <TableChartIcon />,
        title: "Reports",
        link: "dumpReports",
        roleId: [1,10],
    },
    {
        icon: <GroupAddIcon />,
        title: "User Management",
        link: "userManagement",
        roleId: [1,10],
    },
    {
        icon: <PowerSettingsNewOutlinedIcon />,
        title: "Logout",
        link: "logout",
        roleId: [1, 2, 3, 4, 5, 6, 7, 8,10],
    },
    // { icon: <TrendingUpIcon />, title: "InputList", link: "" },

    // {
    //     icon: <img src={ServicesGroup} alt="Production Order Workflow" className="sideDrawerServicesIcon" />, title: "Production Order Workflow", link: "poworkflow"
    // },
    // { icon: <FlightTakeoffOutlinedIcon />, title: "Acknowledge Data", link: "a" },
    // { icon: <DevicesOutlinedIcon />, title: "Reprint QR", link: "" },
    // { icon: <BusinessCenterOutlinedIcon />, title: "Admin", link: "" },
];